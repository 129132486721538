import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import InfiniteScroll from "react-infinite-scroller";
import { IoIosClose, IoIosArrowDown, IoIosSearch } from "react-icons/io";
// import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import styled from "styled-components";

import { Button, Container, Wrapper, Row, Box } from "../components/util";
import { ResourceCard, Sidebar, CallToAction } from "../components/site";
import { EmptyState } from "../components/feedback";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Image from "../components/image";

import arrow1 from "../images/graphics/arrows/arrow_1.svg";

const TopicItem = styled.div`
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;

  &:hover {
    background-color: ${(props) => props.theme.colours.midGrey};
  }

  &.-active {
    background-color: ${(props) => props.theme.colours.Primary};
    color: ${(props) => props.theme.colours.white};

    &:hover {
      background-color: ${(props) => props.theme.colours.Primary};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    text-align: left;
  }
`;

const CustomFeatured = styled(Link)`
  display: block;
  text-decoration: none;
  border-radius: ${(props) => props.theme.styles.borderRadius};
  background-color: ${(props) => props.theme.colours.white};
  border: solid 1px ${(props) => props.theme.colours.grey};
  padding: 40px 60px;
  margin-right: 30px;
  margin-bottom: 30px;
  width: calc(100% - 30px);
  transition: box-shadow ${(props) => props.theme.transitions.med};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: calc((100% / 2) - 30px);
    padding: 30px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: calc(100% - 30px);
  }

  .featured-row {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 60px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      flex-direction: column;

      > * + * {
        margin-left: 0;
      }

      > div {
        width: 100%;
      }
    }
  }

  h3,
  p {
    color: ${(props) => props.theme.colours.dark};
  }

  &:hover {
    /* box-shadow: 0 0 40px rgba(0, 0, 0, 0.2); */

    h3 {
      color: ${(props) => props.theme.colours.Primary} !important;
    }
  }

  .arrow {
    position: absolute;
    top: -40px;
    left: -70px;

    img {
      width: 75px;
      transform: scaleX(-1) rotate(20deg);
    }
  }
`;

const ResourceList = styled.div`
  > div {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 30px);

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100%;
    }
  }
`;

const SearchToggle = styled.div`
  height: 31px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    align-self: flex-start;
  }

  .hideSearch {
    cursor: pointer;
    color: ${(props) => props.theme.colours.midDark};
    min-width: 100px;
    text-align: right;
    line-height: 2;

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      text-align: left;
      min-width: 100%;
    }
  }

  .searchIcon {
    font-size: 1.7rem;
    cursor: pointer;
    position: relative;
    top: 3px;
  }
`;

const Search = styled.form`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 0;

  div {
    position: relative;
    width: 100%;

    input {
      padding-right: 100px;
      padding-left: 0;
      border: none;
      box-shadow: none;
      border-bottom: solid 1px ${(props) => props.theme.colours.greyDarken};
      border-radius: 0;
      font-size: 2rem;
      height: unset;
      margin-bottom: 10px;
      background-color: transparent;

      :focus {
        outline: 0;
        box-shadow: none;
        border-bottom: solid 2px ${(props) => props.theme.colours.inputOutline};
      }
    }

    .search-icon {
      position: absolute;
      left: 15px;
      top: 50%;
      font-size: 1.5rem;
      transform: translateY(-50%);
      z-index: 98;
      color: ${(props) => props.theme.colours.midDark};
    }

    .search-clear {
      position: absolute;
      cursor: pointer;
      right: 75px;
      top: 50%;
      font-size: 2rem;
      transform: translateY(-50%);
      background: ${(props) => props.theme.colours.grey};
      border-radius: 100px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      input {
        padding-left: 0;
        padding-right: 95px;
        font-size: 1rem;
      }

      .search-icon {
        font-size: 1.3rem;
        left: 10px;
      }

      .search-clear {
        right: 52px;
        top: 20px;
      }
    }
  }

  button {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    padding: 0;
    margin-left: 0px;
    z-index: 98;
    width: 70px;
    line-height: 0.75;
    transform: translateY(-50%);
    font-size: 2rem;
    box-shadow: none;
    background-color: transparent;

    svg {
      color: ${(props) => props.theme.colours.blue};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100px;
    }
  }

  > * + * {
    margin-top: 0;
    margin-left: 10px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    input {
      padding-left: 30px;
      padding-right: 145px;
    }

    .search-icon {
      font-size: 1.3rem;
      left: 10px;
    }

    button {
      width: 50px;
      top: 20px;
    }
  }
`;

export const contentfulData = graphql`
  query {
    allContentfulResource(
      sort: { order: DESC, fields: publishedDate }
      filter: { hideFromLibrary: { eq: false } }
    ) {
      edges {
        node {
          isFeaturedContent
          publishedDate
          modifiedDate
          resourceImage {
            gatsbyImageData(width: 440, layout: FIXED, quality: 100)
          }
          resourceTitle
          resourceTopic
          url
          tags
        }
      }
    }
  }
`;

const Blog = (props) => {
  //   const [typeVal, setTypeVal] = useState("all");
  const [topicVal, setTopicVal] = useState("All Topics");
  const [searchVal, setSearchVal] = useState("");
  const [loadVal, setLoadVal] = useState(9);

  const [showFilters, setShowFilters] = useState(true);
  const [showSearch, setShowSearch] = useState(false);

  var itemsList = [];
  var noResults = false;

  let [hasMoreItems, setNoMoreItems] = useState(true);
  let [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setTopicVal(
      decodeURIComponent(
        window.sessionStorage.getItem("blog_topic") || "All Topics"
      )
    );
    setSearchVal(
      decodeURIComponent(window.sessionStorage.getItem("blog_search") || "")
    );
    setLoadVal(parseInt(window.sessionStorage.getItem("blog_load") || 9));
  }, []);

  //   const handleTopicChange = (e) => {
  //     e.target.value !== "all"
  //       ? window.sessionStorage.setItem("blog_topic", e.target.value)
  //       : window.sessionStorage.removeItem("blog_topic");
  //     setTopicVal(e.target.value);
  //   };

  function handleTopic(topic) {
    window.sessionStorage.setItem("blog_topic", topic);
    setTopicVal(topic);
  }

  const handleSearch = (e) => {
    if (e) {
      e.preventDefault();
      window.sessionStorage.setItem("blog_search", e.target[0].value);
      setSearchVal(e.target[0].value);
    }
  };

  const handleLoad = () => {
    setLoading(true);

    if (loadVal > itemsList.length) {
      setLoading(false);
      setNoMoreItems(false);
    } else {
      setTimeout(() => {
        setLoading(false);
        window.sessionStorage.setItem("blog_load", JSON.stringify(loadVal + 9));
        setLoadVal(loadVal + 9);
        loadPosts();
      }, 1000);
    }
  };

  function searchPosts(post) {
    var postTags = post.tags !== null ? post.tags : [];
    var SEARCH = searchVal.toUpperCase();

    for (var i = 0; i < postTags.length; i++) {
      if (postTags[i].toUpperCase().indexOf(SEARCH) !== -1) {
        return true;
      }
    }

    if (post.resourceTitle.toUpperCase().indexOf(SEARCH) !== -1) {
      return true;
    } else if (searchVal === "") {
      return true;
    } else {
      return false;
    }
  }

  const removeSearch = () => {
    var searchInput = document.getElementById("searchInput");
    window.sessionStorage.removeItem("blog_search");
    setSearchVal("");
    searchInput.value = "";
    searchInput.focus();
  };

  const showResource = (resource) => {
    var topicMatch =
      resource.resourceTopic ===
        topicVal.charAt(0).toUpperCase() + topicVal.slice(1) ||
      topicVal === "All Topics";
    var searchMatch = searchPosts(resource);

    if (topicMatch && searchMatch) {
      return true;
    }
  };

  const handleShowSearch = () => {
    setShowSearch(!showSearch);

    setTimeout(() => {
      if (!showSearch) {
        var searchInput = document.getElementById("searchInput");
        searchInput.focus();
      }
    }, 50);
  };

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  function loadPosts() {
    itemsList = props.data.allContentfulResource.edges
      .filter(function(resource) {
        return showResource(resource.node);
      })
      .map((edge, i) => {
        return (
          <>
            <ResourceCard
              isFeatured={false}
              guestAuthor_image={
                edge.node.guestAuthorImage &&
                edge.node.guestAuthorImage.gatsbyImageData
              }
              guestAuthor_name={edge.node.guestAuthorTitle}
              guestAuthor_role={edge.node.guestAuthorRole}
              key={i}
              image={
                edge.node.resourceImage &&
                edge.node.resourceImage.gatsbyImageData
              }
              type={edge.node.resourceType}
              topic={edge.node.resourceTopic}
              title={edge.node.resourceTitle}
              excerpt={edge.node.excerpt}
              date={edge.node.publishedDate}
              url={edge.node.url}
              show={true}
            />
            {i === 2 &&
              !searchVal &&
              (topicVal === "All Topics" ||
                topicVal === "Payroll & Finance") && (
                <CustomFeatured to="/guides/switching-payroll-systems">
                  <div className="featured-row">
                    <Box size={50}>
                      <Image
                        filename="PayrollGuide_Hero.png"
                        alt="Guide to Switching Payroll Systems"
                        rounded
                        addShadow
                        centerImage
                        maxWidth={500}
                      />
                    </Box>
                    <Box size={50} stackGap={10}>
                      <div className="arrow hideOnTablet">
                        <img src={arrow1} alt="Arrow" />
                      </div>
                      <h6>
                        <div className="badge -sm -PayrollFinance">
                          Payroll & Finance
                        </div>
                      </h6>
                      <h3>
                        <b>
                          The Step-by-Step Guide to Switching Your Payroll
                          System
                        </b>
                      </h3>
                      <p>
                        Are you looking to change your payroll provider, but not
                        sure where to begin? Our step-by-step guide to switching
                        payroll software walks you through everything to ensure
                        the changeover process goes as smoothly as possible.
                      </p>
                    </Box>
                  </div>
                </CustomFeatured>
              )}
          </>
        );
      })
      .slice(0, loadVal);

    if (itemsList.length < 1) {
      noResults = true;
    }

    if (itemsList.length < 9) {
      hasMoreItems = false;
    }

    return itemsList;
  }

  const handleEmpty = () => {
    return;
  };

  const topics = [
    "All Topics",
    "Payroll & Finance",
    "Hiring & Growth",
    "Team Management",
    "Holidays Act",
    "Product Update",
  ];

  return (
    <Layout>
      <Seo
        title="Employment & Payroll Resources | NZ Payroll Blog & News | PayHero"
        description="Navigate your way through the ins and outs of managing and paying your team with our range of handy guides, videos and articles."
        pathname={props.location.pathname}
      />
      <Container bg="lightGrey">
        <Wrapper stackGap={60}>
          <div className="-textCenter">
            <h1>Payroll Resources</h1>
          </div>
        </Wrapper>
      </Container>
      <Container className="showOnMobile">
        <Wrapper className="-textCenter" stackGap={30}>
          <div onClick={handleShowFilters}>
            {showFilters ? "Hide" : "Show"} Filters{" "}
            <IoIosArrowDown
              css={{
                position: "relative",
                top: "2px",
                transform: showFilters ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </div>
        </Wrapper>
      </Container>
      {showFilters && (
        <Container>
          <Wrapper stackGap={40} noPaddingBottom>
            <Box stackGap={20}>
              <Row alignCenter>
                <Row stackGap={10} justify="flex-start">
                  {topics.map((item, i) => {
                    return (
                      <TopicItem
                        className={item === topicVal ? "-active" : ""}
                        onClick={() => {
                          handleTopic(item);
                        }}
                      >
                        {item}
                      </TopicItem>
                    );
                  })}
                </Row>
                <SearchToggle onClick={handleShowSearch}>
                  {!searchVal &&
                    (showSearch ? (
                      <div className="hideSearch">Hide Search</div>
                    ) : (
                      <IoIosSearch className="searchIcon" />
                    ))}
                </SearchToggle>
              </Row>
              {(showSearch || searchVal) && (
                <Search onSubmit={handleSearch}>
                  <div>
                    <input
                      type="text"
                      id="searchInput"
                      placeholder="Search blog posts, guides, checklists and more..."
                      defaultValue={searchVal}
                    />
                    {searchVal && (
                      <IoIosClose
                        className="search-clear"
                        title="Clear Search"
                        onClick={removeSearch}
                      />
                    )}
                  </div>
                  <button className="white" type="submit">
                    <IoIosSearch />
                  </button>
                </Search>
              )}
            </Box>
          </Wrapper>
        </Container>
      )}
      <Container css={{ overflow: "unset" }}>
        <Wrapper stackGap={40}>
          <Row stackGap={40}>
            <Box size={75} stackGap={60}>
              <ResourceList>
                <InfiniteScroll
                  hasMore={hasMoreItems}
                  loader={false}
                  loadMore={handleEmpty}
                >
                  {loadPosts()}
                </InfiniteScroll>
              </ResourceList>
              <div css={{ marginTop: "0 !important" }}>
                <EmptyState
                  show={noResults}
                  message="No Resources were found for your search"
                />
              </div>
              <Button
                css={{
                  display: hasMoreItems ? "table" : "none",
                  pointerEvents: isLoading ? "none" : "all",
                  opacity: isLoading ? "0.5" : "1",
                }}
                className="grey -lg -center"
                onClick={handleLoad}
                atag
              >
                {isLoading ? "Loading More..." : "Load More"}
              </Button>
            </Box>
            <Box size={25} css={{ position: "sticky", top: "80px" }}>
              <Sidebar product="payhero" />
            </Box>
          </Row>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default Blog;
